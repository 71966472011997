<template>
  <main ref="page">
    <div class="container">
      <h1>Scarico catalogo</h1>

      <div class="row">
        <div class="col-auto">
          <button class="btn btn-primary" :disabled="scanOn || processing" @click.prevent="(e) => (startScan() && e.target.blur())">Start scan</button>
        </div>
        <div class="col"></div>
        <div class="col-auto text-center">Scan mode: {{ (scanOn && !processing) ? 'On' : 'Off' }}</div>
        <div class="col"></div>
        <div class="col-auto">
          <button class="btn btn-primary" :disabled="!scanOn || processing" @click.prevent="(e) => (stopScan() && e.target.blur())">Stop scan</button>
        </div>
      </div>

      <p class="mt-4">Buffer di lettura: {{ key }}</p>

      <div>
        <table class="table">
          <thead>
          <tr>
            <th>Produttore</th>
            <th>Vino</th>
            <th>Annata</th>
            <th>Quantità da rimuovere</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="productId in Object.keys(productQuantity)" :key="productId">
            <td>{{ products[productId].producerName }}</td>
            <td>{{ products[productId].wineName }}</td>
            <td>{{ products[productId].vintageName }}</td>
            <td>{{ productQuantity[productId] }}</td>
            <td><button class="btn btn-outline-secondary" :disabled="processing" @click="(e) => removeProductUnit(productId, e)">-</button></td>
            <td><button class="btn btn-outline-secondary" :disabled="processing" @click="(e) => addProductUnit(productId, e)">+</button></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-4">
        <button class="btn btn-primary" :disabled="howManyProducts === 0" @click.prevent="saveReassortment">Salva lo scarico del catalogo</button>
        <span v-if="errorSaving" class="ms-4">Errore durante il salvataggio</span>
        <span v-else-if="processing" class="ms-4">Salvataggio in corso...</span>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  data () {
    return {
      key: '',
      codeRead: false,
      scanOn: false,
      processing: false,
      errorSaving: false,

      products: {},
      productQuantity: {},
    };
  },
  computed: {
    howManyProducts () {
      return Object.keys(this.productQuantity).filter(key => !!this.productQuantity[key]).length;
    },
  },
  methods: {
    manageCode (code) {
      if (!this.products[code]) {
        this.$api.fetchWinePackage(code)
          .then(res => {
            this.$set(this.products, code, res.data);
            this.$set(this.productQuantity, code, 1);
            this.codeRead = false;
          })
          .catch(() => {
            this.codeRead = false;
          })
        ;
      } else {
        this.productQuantity[code]++;
        this.codeRead = false;
      }
    },
    handleKeyUp (e) {
      if (!this.scanOn || this.processing) {
        return;
      }

      if (e.key === 'Enter') {
        if (this.codeRead) {
          this.key = '';
          return;
        }

        this.codeRead = true;
        const code = this.key;
        this.manageCode(code.toUpperCase());
        this.key = '';

        return;
      }

      if (
        (e.keyCode >= 97 && e.keyCode <= 122)
        ||
        (e.keyCode >= 65 && e.keyCode <= 90)
        ||
        (e.keyCode >= 48 && e.keyCode <= 57)
      ) {
        this.key += e.key;
      }
    },
    startScan () {
      const body = document.getElementsByTagName('body')[0];
      body.addEventListener('keyup', this.handleKeyUp, { passive: true });
      this.scanOn = true;
    },
    stopScan () {
      const body = document.getElementsByTagName('body')[0];
      body.removeEventListener('keyup', this.handleKeyUp);
      this.scanOn = false;
    },
    removeProductUnit (productId, e) {
      e.target.blur();
      if (!this.productQuantity[productId]) {
        return;
      }

      this.productQuantity[productId]--;
    },
    addProductUnit (productId, e) {
      e.target.blur();
      if (!this.productQuantity[productId] && 0 !== this.productQuantity[productId]) {
        return;
      }

      this.productQuantity[productId]++;
    },
    saveReassortment () {
      if (this.howManyProducts === 0) {
        return;
      }

      this.errorSaving = false;
      this.processing = true;

      this.$api.batchDecreaseWinePackageQuantities({ ...this.productQuantity })
        .then(() => {
          this.products = {};
          this.productQuantity = {};
        })
        .catch(() => {
          this.errorSaving = true;
        })
        .finally(() => {
          this.processing = false;
        })
      ;
    },
  },
  beforeDestroy () {
    if (!this.scanOn) {
      return;
    }

    this.stopScan();
  },
};

</script>
